import { compose } from "redux";
import { withApollo } from "react-apollo";
import { useState, useEffect } from "react";
import { getAllData } from "../../utils/helpers/fetching";
import { sortBy, uniqBy } from "lodash";
import { SELECT_ALL_VALUE } from "@digitallab/grid-common-components";
import React from "react";
import { OwcSelectWithChip as OwcSelectWithChip2 } from "./OwcSelectWithChip";

/**
 * Multiselect with search - displays selected options below as Chips
 * Select values taken from provided query
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 * @param {object} props.client - Apollo client
 * @param {string} props.dataKey - name of select field for form
 * @param {string} props.label - label/visible description of select field
 * @param {Object} props.queryDetails - query params as object or array of objects
 * @param {Object} props.queryDetails.query - GQL query
 * @param {Object} props.queryDetails.dataPath - array with path to data in query response
 * @param {Object} props.queryDetails.responseDataKey - name of property for values to select from data array received in query response
 */

export const SelectWithChipGetData = compose(withApollo)(({ client, formik, dataKey, queryDetails, label }) => {
  const [data, setData] = useState({ loading: false, error: false, optionsList: [] });

  const processQuery = async (queryDetails) => {
    const { query, dataPath, valueKey, variables, labelFunction } = queryDetails;
    const response = await getAllData({
      client,
      query,
      variables: variables ? variables : { limit: 1000 },
      dataPath
    });
    const { items, error, errors } = response;

    if (error) throw new Error(error);
    if (errors) {
      if (Array.isArray(errors)) {
        const errorsJoined = error.map((error) => error.message).join(", ");
        throw new Error(errorsJoined);
      } else throw new Error(errors);
    }
    const result = items.map((item) => ({
      label: labelFunction ? labelFunction(item) : item[valueKey],
      value: item[valueKey]
    }));
    return result;
  };

  useEffect(() => {
    const getOptionsList = async () => {
      setData({ ...data, loading: true, error: false });
      let optionsList = [];
      try {
        if (Array.isArray(queryDetails))
          optionsList = [].concat(...(await Promise.all(queryDetails.map(async (query) => await processQuery(query)))));
        else optionsList = optionsList.concat(await processQuery(queryDetails));

        const uniqueOptionsList = uniqBy(optionsList, "value");
        const sortedOptionsList = sortBy(uniqueOptionsList, ["label"]);
        sortedOptionsList.unshift(SELECT_ALL_VALUE);
        data.optionsList = sortedOptionsList;
      } catch (error) {
        data.error = error;
        console.error("Error fetching data:", error);
      } finally {
        setData({ ...data, loading: false });
      }
    };

    getOptionsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, JSON.stringify(queryDetails)]);

  return <OwcSelectWithChip2 key={dataKey} formik={formik} dataKey={dataKey} label={label} data={data} />;
});
