import React from "react";
import { OwcModalDialog, OwcButton, OwcIcon, OwcTypography } from "@one/react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useFormikContext } from "formik";
import { OwcTextarea } from "@one/react";
import CustomSearchText from "./CustomSearchText";

const IdentificationForm = styled.div`
  & > .selectBoxLarge {
    margin: 0 0 25px 0;
    width: 418px;
  }
`;

export const AddReviewerWithoutReason = ({
  testid = "confirm-dialog",
  title = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  renderActions,
  disableBackdropClick = false,
  reviewers = [],
  isReviewerUpdate = false
}) => {
  const formik = useFormikContext();
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel(formik);
    }
    formik.setFieldValue("editComment", "", false);
    formik.setFieldValue("reviewers", [], false);
  };
  const handleApprove = () => {
    close();
    if (onApprove) {
      onApprove();
    }
  };

  return (
    <OwcModalDialog
      data-testid={testid}
      disableScrollLock
      visible={open}
      size="md"
      className="owcmodalZIndex"
      disableBackdropClick={disableBackdropClick}
    >
      <IdentificationForm>
        {isReviewerUpdate && (
          <>
            <CustomSearchText
              id="reviewers"
              dataTestid={`reviewers-inputs-reviewers-input`}
              disabled={reviewers.length === 0}
              optionsList={reviewers}
              propValue="key"
              propLabel="value"
              label={reviewers.length === 0 ? "No reviewer available" : "Reviewer"}
              value={formik.values?.reviewers || []}
              setValue={(newValue) => {
                formik.setFieldValue("reviewers", newValue, true);
              }}
              name="reviewers"
              fullWidth={true}
              error={formik.touched["reviewers"] && Boolean(formik.errors["reviewers"])}
              helperText={formik.touched["reviewers"] && formik.errors["reviewers"]}
              multiple={true}
              onBlur={formik.handleBlur}
              required={true}
            />
            <OwcTypography variant="subtitle2" style={{ padding: "0px 0px 20px 0px" }}></OwcTypography>
          </>
        )}
        {(formik.values?.configText || isReviewerUpdate) && (
          <OwcTextarea
            formik={formik}
            data-testid={`textarea-field-edit-comment`}
            label={isReviewerUpdate ? "Comment " : "Add comment "}
            name="editComment"
            validation-mode="realtime"
            error={formik.values.editComment === ""}
            onInputReset={() => formik.setFieldValue("editComment", "", true)}
            value={formik.values?.editComment}
            onInputChange={formik.handleChange}
            onInputKeyDown={formik.handleChange}
            onBlur={formik.handleBlur}
            reset
            cols="18"
            maxLength={80}
            minLength={0}
            minRows={2}
          />
        )}
      </IdentificationForm>
      <div slot="header">
        {title === "Master data already exists" && (
          <OwcIcon
            name="alarm"
            type="outlined"
            style={{
              color: "var(--one-color-interaction-default-warning-1)",
              paddingRight: "2px"
            }}
          />
        )}
        {title}
      </div>
      <div
        slot="actions"
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close
          })
        ) : (
          <div style={{ display: "flex" }}>
            {cancelText && (
              <OwcButton
                data-testid="confirm-dialog-actions-button-cancel"
                onClick={handleCancel}
                variant="secondary"
                style={{ marginRight: 32 }}
              >
                {cancelText || null}
              </OwcButton>
            )}
            {approveText && (
              <OwcButton
                disabled={isReviewerUpdate ? (formik.values?.reviewers?.length ?? 0) === 0 : false}
                data-testid="confirm-dialog-actions-button-approve"
                onClick={handleApprove}
                variant={approveColor}
              >
                {approveText || null}
              </OwcButton>
            )}
          </div>
        )}
      </div>
    </OwcModalDialog>
  );
};
const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail
});
export default connect(mapStateToProps, null)(AddReviewerWithoutReason);
