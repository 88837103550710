import React, { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { uniq } from "underscore";
import { MainDescriptionEditableDropdown } from "../cluster/steps/MainDescriptionEditableDropdown";

export const MainDescription = () => {
  const formik = useFormikContext();
  const categoryMappingList = useSelector((state) => state.instruments?.categoryMappingMasterList);

  const categoryList = useMemo(
    () => uniq(categoryMappingList.map((item) => item.equipmentCategory)).sort(),
    [categoryMappingList]
  );

  const manufacturersInCategory = useMemo(() => {
    if ([null, ""].includes(formik.values?.equipmentCategory)) return [];
    else
      return uniq(
        categoryMappingList
          .filter((item) => item.equipmentCategory === formik.values?.equipmentCategory)
          .map((item) => item.manufacturer)
      ).sort();
  }, [categoryMappingList, formik.values?.equipmentCategory]);

  const modelsInManufacturer = useMemo(() => {
    if ([null, ""].includes(formik.values?.manufacturer) || [null, ""].includes(formik.values?.equipmentCategory))
      return [];
    else
      return uniq(
        categoryMappingList
          .filter(
            (item) =>
              item.equipmentCategory === formik.values?.equipmentCategory &&
              item.manufacturer === formik.values?.manufacturer
          )
          .map((item) => item.equipmentModel)
      ).sort();
  }, [categoryMappingList, formik.values?.equipmentCategory, formik.values?.manufacturer]);

  const variantsInModel = useMemo(() => {
    if (
      [null, ""].includes(formik.values?.equipmentModel) ||
      [null, ""].includes(formik.values?.manufacturer) ||
      [null, ""].includes(formik.values?.equipmentCategory)
    )
      return [];
    else
      return uniq(
        categoryMappingList
          .filter(
            (item) =>
              item.equipmentCategory === formik.values?.equipmentCategory &&
              item.manufacturer === formik.values?.manufacturer &&
              item.equipmentModel === formik.values?.equipmentModel
          )
          .map((item) => item.variant)
      ).sort();
  }, [
    categoryMappingList,
    formik.values?.equipmentCategory,
    formik.values?.manufacturer,
    formik.values?.equipmentModel
  ]);

  useEffect(() => {
    if (manufacturersInCategory.length > 0 && !manufacturersInCategory.includes(formik.values?.manufacturer)) {
      if (manufacturersInCategory.length === 1) formik.setFieldValue("manufacturer", manufacturersInCategory[0], true);
      else formik.setFieldValue("manufacturer", null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.manufacturer, manufacturersInCategory]);

  useEffect(() => {
    if (modelsInManufacturer.length > 0 && !modelsInManufacturer.includes(formik.values?.equipmentModel)) {
      if (modelsInManufacturer.length === 1) formik.setFieldValue("equipmentModel", modelsInManufacturer[0], true);
      else formik.setFieldValue("equipmentModel", null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.equipmentModel, formik.values?.manufacturer, modelsInManufacturer]);

  useEffect(() => {
    if (variantsInModel.length > 0 && !variantsInModel.includes(formik.values?.variant)) {
      if (variantsInModel.length >= 1) formik.setFieldValue("variant", variantsInModel[0], true);
      else formik.setFieldValue("variant", null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.equipmentModel, formik.values?.manufacturer, formik.values?.equipmentVariant, variantsInModel]);

  const categoryChange = (optionIndex) => {
    const optionValue = categoryList?.find((_, index) => index === optionIndex);
    formik.setFieldValue("equipmentCategory", optionValue, true);
  };

  const manufacturerChange = (optionIndex) => {
    const optionValue = manufacturersInCategory?.find((_, index) => index === optionIndex);
    formik.setFieldValue("manufacturer", optionValue, true);
  };

  const modelChange = (optionIndex) => {
    const optionValue = modelsInManufacturer?.find((_, index) => index === optionIndex);
    formik.setFieldValue("equipmentModel", optionValue, true);
  };

  const variantChange = (optionIndex) => {
    if (optionIndex === null) {
      formik.setFieldValue("variant", formik.values?.variant, true);
      return;
    }
    const optionValue = variantsInModel?.find((_, index) => index === optionIndex);
    formik.setFieldValue("variant", optionValue, true);
  };
  return (
    <>
      <MainDescriptionEditableDropdown
        list={categoryList}
        handleOnChange={categoryChange}
        dataModel="equipmentCategory"
        disabled = {formik.values.linkedInstance?.linkedInstanceDisplay==='None'}
      />
      <MainDescriptionEditableDropdown
        list={manufacturersInCategory}
        handleOnChange={manufacturerChange}
        dataModel="manufacturer"
        disabled = {formik.values.linkedInstance?.linkedInstanceDisplay==='None'}
      />
      <MainDescriptionEditableDropdown
        list={modelsInManufacturer}
        handleOnChange={modelChange}
        dataModel="equipmentModel"
        disabled = {formik.values.linkedInstance?.linkedInstanceDisplay  ==='None'}
      />
      <MainDescriptionEditableDropdown
        list={variantsInModel}
        handleOnChange={variantChange}
        dataModel="variant"
        required={false}
      />
    </>
  );
};
