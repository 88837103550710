import omitDeep from "omit-deep-lodash";
import {
  GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRYS,
  LIST_IR_ENTRY_NONE_UNICITY_CHECK,
  LIST_IR_ENTRY_UNICITY_CHECK
} from "../../../gql/landingapi";

/**
 * This method is used to check the unicity of the instrument that is being added
 * @param {*} id the  id of the instrument
 * @returns the instrument object if the instrument already exists
 * or returns null if instrument is not present
 * It calls an gql query to check the unicity
 */

export const checkUnicityForEquipmentOnEdit = async (id, client, setIsLoading) => {
  try {
    setIsLoading(true);
    return await client.query({
      query: GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
      fetchPolicy: "no-cache",
      variables: {
        id: id
      }
    });
  } catch (err) {
    console.log("unicity check error", err);
  } finally {
    setIsLoading(false);
  }
};

export const checkUnicityForEquipmentOnAdd = async (linkedInstance, secondaryID, client, setIsLoading) => {
  try {
    if (linkedInstance?.linkedInstance === "None") {
      const [manufacturer,equipmentModel,serialNumber] = secondaryID?.split(";");
      setIsLoading(true);
      const result = await client.query({
        query: LIST_IR_ENTRY_NONE_UNICITY_CHECK,
        fetchPolicy: "no-cache",
        variables: {
          manufacturer,
          equipmentModel,
          serialNumber
        }
      });
      const unicityRecord = result?.data?.listIREntryByManufacturerModelAndSNo?.items;
      return unicityRecord ?? [];
    } else {
      setIsLoading(true);
      const result = await client.query({
        query: LIST_IR_ENTRY_UNICITY_CHECK,
        fetchPolicy: "no-cache",
        variables: {
          linkedInstance: linkedInstance?.linkedInstance,
          secondaryID
        }
      });
      const unicityRecord = result?.data?.listIREntryByLinkedInstanceAndSecondaryID?.items;
      return unicityRecord ?? [];
    }
  } catch (err) {
    console.log("unicity check error", err);
  } finally {
    setIsLoading(false);
  }
};

export const getEquipmentListAndUpdateRedux = async (client, limit) => {
  try {
    const result = await client.query({
      query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRYS,
      fetchPolicy: "no-cache",
      variables: {
        limit: limit
      }
    });
    return omitDeep(result?.data?.listDigitalLabInstrumentRepositoryEntrys?.items, "__typename");
  } catch (err) {
    console.log("unicity check error", err);
  }
};
