import React, { useContext } from "react";
import { GroupContext } from "./context";
import { useFormikContext } from "formik";
import { OwcIconButton } from "@one/react";
import Tooltip from "@mui/material/Tooltip";
import { userLabel } from "./GroupDialog";

const Actions = ({ item }) => {
  const { setDialogOpen, setIsEditMode } = useContext(GroupContext);
  const formik = useFormikContext();
  const createUserObj = (list) => list?.map((item) => ({ value: item.userId, label: userLabel(item) }));

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
      <Tooltip
        data-testid={"equipment-edit-button-tooltip" + item.groupName}
        title={"Edit group access"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          onClick={() => {
            setIsEditMode(true);
            formik.resetForm();
            formik.setFieldValue("groupName", item.groupName, true);
            formik.setFieldValue("userIds", createUserObj(item.userIds), true);
            formik.setFieldValue("reviewers", createUserObj(item.reviewers), true);
            setDialogOpen(true);
          }}
          icon="edit"
          type="legacy"
          flat
        />
      </Tooltip>
    </div>
  );
};
export default Actions;
