import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Autocomplete, Popper, TextField } from "@mui/material";
import { OwcChip, OwcTypography } from "@one/react";
import { themeMapping } from "@one/web-components";
import { THEME_NAME } from "../../constants";
import { SELECT_ALL_VALUE } from "@digitallab/grid-common-components";
import React from "react";

const THEME = themeMapping[THEME_NAME];
const BORDER_STYLE = `${THEME["one-box-shadow-positive-1"]} ${THEME["one-border-style-solid"]} ${THEME["one-color-cobas-gray-400"]}`;

/**
 * Multiselect with search - displays selected options below as Chips
 * Select values taken from provided query
 * @param {object} props
 * @param {object} props.formik - Formik state and methods
 * @param {object} props.client - Apollo client
 * @param {string} props.dataKey - name of select field for form
 * @param {string} props.label - label/visible description of select field
 * @param {Object} props.queryDetails - query params as object or array of objects
 * @param {Object} props.queryDetails.query - GQL query
 * @param {Object} props.queryDetails.dataPath - array with path to data in query response
 * @param {Object} props.queryDetails.responseDataKey - name of property for values to select from data array received in query response
 */

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{
        border: BORDER_STYLE,
        borderRadius: THEME["one-border-radius-4"],
        ...props.style
      }}
      placement="bottom-start"
    />
  );
};

export const OwcSelectWithChip = compose(withApollo)(({ data, formik, dataKey, label, noChipsText }) => {
  const values = formik?.values?.[dataKey] || [];
  const { optionsList = [], loading, error } = data;

  return (
    <>
      <Autocomplete
        name={dataKey}
        multiple
        disableCloseOnSelect
        options={optionsList}
        PopperComponent={PopperMy}
        sx={{ width: THEME["one-sizer-base-100-p"] }}
        loading={loading}
        noOptionsText={error ? "Error fetching data" : "No options"}
        value={values}
        onBlur={() => {
          formik?.setFieldTouched(dataKey, true);
        }}
        onChange={(_, selectValue) => {
          const containsValueFromSelectAll = (myArray) => myArray.some(({ value }) => value === SELECT_ALL_VALUE.value);

          if (selectValue.length === 2 && containsValueFromSelectAll(selectValue) && containsValueFromSelectAll(values))
            formik?.setValues({
              ...formik?.values,
              [dataKey]: [...selectValue.filter((item) => item.value !== SELECT_ALL_VALUE.value)]
            });
          else
            formik?.setValues({
              ...formik?.values,
              [dataKey]: containsValueFromSelectAll(selectValue) ? [SELECT_ALL_VALUE] : selectValue
            });
        }}
        isOptionEqualToValue={(option, passedValue) => passedValue.value === option.value}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              borderBottom: BORDER_STYLE,
              padding: THEME["one-spacer-8"]
            }}
          >
            <input
              type="checkbox"
              readOnly
              style={{
                marginRight: THEME["one-spacer-8"],
                cursor: "pointer"
              }}
              checked={values.map(({ value }) => value).includes(option.value)}
            />

            <OwcTypography>{option.label}</OwcTypography>
          </li>
        )}
        renderTags={() => (
          <>
            {values.length > 0 && (
              <>
                <OwcTypography>{values[0].label}</OwcTypography>
                {values.length > 1 && <span style={{ marginLeft: THEME["one-spacer-8"] }}>+{values.length - 1}</span>}
              </>
            )}
          </>
        )}
        renderInput={(params) => {
          const hasValue = values.length > 0;
          return (
            <TextField
              helperText={formik?.touched[dataKey] && formik?.errors[dataKey]}
              error={formik?.touched[dataKey] && !!formik?.errors[dataKey]}
              placeholder={(!hasValue && label) || ""}
              variant="filled"
              name={dataKey}
              label={label}
              {...params}
            />
          );
        }}
      />
      <br />
      <div
        style={{
          margin: `${THEME["one-spacer-12"]} 0 ${THEME["one-spacer-12"]} 0`,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          gap: THEME["one-spacer-12"],
          maxHeight: 500,
          minHeight: THEME["one-sizer-base-200"],
          overflow: "scroll"
        }}
      >
        {Array.isArray(values) && values.length > 0 ? (
          values.map((option, index) => (
            <OwcChip
              key={index + option}
              removable
              round
              onRemove={() => {
                const arrayCopy = [...values];
                arrayCopy.splice(index, 1);
                formik?.setFieldTouched(dataKey, true);
                return formik?.setValues({
                  ...formik?.values,
                  [dataKey]: arrayCopy
                });
              }}
            >
              {option.label}
            </OwcChip>
          ))
        ) : (
          <OwcTypography>{noChipsText ? noChipsText : `Selected ${dataKey} will appear here`}</OwcTypography>
        )}
      </div>
    </>
  );
});
