import React, { useState, useEffect, useContext } from "react";
import CustomTable from "../../components/shared/ListOwcTable";
import TopBar from "../instruments/TopBar";

import GroupDialog from "./GroupDialog";
import { getAllData } from "../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { lIST_IR_GROUP_ADMIN_MAPPINGS } from "../../gql/landingapi/queries";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { GroupContext } from "./context";
import { GroupTableMeta } from "./GroupMeta";
import TopBarButton from "./TopBarButton";
import { Formik } from "formik";
import { groupValidationSchema } from "./validationSchema";
import { CommonContextWrapper } from "../../common-context/context";
import { getCurrentDatas } from "../instruments/instrument-repository-pagination/PaginationActions";
import { DEFAULT_RECORDS_FETCHED } from "../../constants";

const GroupContent = ({ client }) => {
  const { dispatchAction, fetching, limit, orderBy, sortDirection, refreshData, nextToken } =
    useContext(PaginationContext);
  const { dialogOpen, setDialogOpen, isEditMode } = useContext(GroupContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalGroup, setTotalGroup] = useState([]);
  const { saveLastPageSize } = useContext(CommonContextWrapper);
  const [groupList, setGroupList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");

  const groupGql = async (isTokenNull = false) => {
    let variables = {
      limit: DEFAULT_RECORDS_FETCHED,
      nextToken: isTokenNull ? null : nextToken
    };
    if (searchGroup !== "") {
      variables = {
        ...variables,
        groupName: { beginsWith: searchGroup }
      };
    }
    return getAllData({
      client,
      query: lIST_IR_GROUP_ADMIN_MAPPINGS,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listIRGroupAdminMappings"],
      drillData: false
    });
  };

  const getGroupList = async () => {
    dispatchAction({
      type: "fetching",
      payload: true
    });
    setPageIndex(0);
    const { items, nextToken: token } = await groupGql(true);

    dispatchAction({
      type: "nextToken",
      payload: { token: token, data: items }
    });
    items.map((item) => (item.userIdsString = item.userIds.join()));
    const currentItems = getCurrentDatas(limit, 0, items);
    setTotalGroup(items);
    setGroupList(currentItems);
  };

  useEffect(() => {
    getGroupList();
    saveLastPageSize(limit, "ir_group");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroup, orderBy, sortDirection, limit, refreshData]);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Formik
      validationSchema={groupValidationSchema}
      initialValues={{ groupName: "", userIds: null }}
      isInitialValid={false}
      validateOnMount={false}
    >
      <>
        <GroupDialog open={dialogOpen} onCancel={closeDialog} isEditMode={isEditMode} client={client} />
        <div style={{ marginBottom: 10 }}>
          <TopBar setQuery={setSearchGroup} placeholder="Search for group" compact>
            <TopBarButton setDialogOpen={setDialogOpen} />
          </TopBar>
        </div>

        <CustomTable
          filteredDatas={groupList}
          setFilteredDatas={setGroupList}
          handlerQueryDatas={groupGql}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalDatas={totalGroup}
          setTotalDatas={setTotalGroup}
          meta={GroupTableMeta}
          data={groupList}
          fieldArray={[]}
          onRequestSort={false}
          isReverseOrder={true}
          orderBy={"name"}
          fetching={fetching}
        />
      </>
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  user: state.user
});

export default compose(connect(mapStateToProps), withApollo)(GroupContent);
