import Actions from "./Actions";
import { UsersCell } from "../../components/shared/CustomCell";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import React from "react";

export const GroupTableMeta = {
  fields: {
    groupName: {
      text: DATA_MODEL_TABLE.belongingToGroup.value,
      headProps: {},
      cellProps: {}
    },
    userIdsString: {
      component: (item) => <UsersCell item={item.item} selector="userIds" />,
      text: "Users",
      headProps: {},
      cellProps: {}
    },
    reviewers: {
      component: (item) => <UsersCell item={item.item} selector="reviewers" />,
      text: "Reviewers",
      headProps: {},
      cellProps: {}
    },
    action: {
      component: Actions,
      headProps: {},
      cellProps: {}
    }
  }
};

export default GroupTableMeta;
