import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  mutation createDigitalLabInstrumentRepositoryEntry($instrument: CreateDigitalLabInstrumentRepositoryEntryInput!) {
    createDigitalLabInstrumentRepositoryEntry(input: $instrument) {
      belongingToGroup
      buildingLocation {
        key
        value
      }
      checkInGracePeriod
      checkInRequired
      checkInStartPeriod
      cluster
      comment
      configurationBaseline
      costCenter
      createdAt
      createdBy
      csv
      dateOfLastMaintanance
      dateOfNextMaintanance
      dateOfNextPeriodicReview
      documentedMaintenanceDate
      displayImage
      electronicRecord
      electronicSignatures
      equipmentAdministrator
      equipmentCategory
      equipmentId
      equipmentModel
      equipmentNickName
      floor {
        key
        value
      }
      functionalLocation
      gxpRelevant
      installedTests {
        name
        version
      }
      instrumentGTIN
      instrumentRUDI
      id
      inventoryNumber
      isBookable
      isVisualized
      linkedInstance
      location
      maintenanceIntervalInDays
      maintenancePlan
      manufacturer
      materialNumber
      modelName
      network
      qualificationDocuments {
        value {
          documentId
          name
        }
        isSynchronized
      }
      qualificationStatus
      controlledEquipmentStatus
      responsiblePerson
      responsibleProxy
      room {
        key
        value
      }
      secondaryID
      secondaryIDMapping
      serialNumber
      siteId
      siteName
      siteTimezone
      softwareVersion
      sop {
        key
        value
      }
      status
      systemOwner
      systemStatus
      updatedAt
      updatedBy
      variant
      entryType
      configurationDetails
    }
  }
`;
export const UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  mutation updateDigitalLabInstrumentRepositoryEntry($instrument: UpdateDigitalLabInstrumentRepositoryEntryInput!) {
    updateDigitalLabInstrumentRepositoryEntry(input: $instrument) {
      belongingToGroup
      buildingLocation {
        key
        value
      }
      checkInGracePeriod
      checkInRequired
      checkInStartPeriod
      cluster
      comment
      configurationBaseline
      costCenter
      csv
      dateOfLastMaintanance
      dateOfNextMaintanance
      dateOfNextPeriodicReview
      documentedMaintenanceDate
      displayImage
      electronicRecord
      electronicSignatures
      equipmentAdministrator
      equipmentCategory
      equipmentId
      equipmentModel
      equipmentNickName
      floor {
        key
        value
      }
      functionalLocation
      gxpRelevant
      installedTests {
        name
        version
      }
      instrumentGTIN
      instrumentRUDI
      id
      inventoryNumber
      isBookable
      isVisualized
      linkedInstance
      location
      maintenanceIntervalInDays
      maintenancePlan
      manufacturer
      materialNumber
      modelName
      network
      qualificationDocuments {
        value {
          documentId
          name
        }
        isSynchronized
      }
      qualificationStatus
      controlledEquipmentStatus
      responsiblePerson
      responsibleProxy
      room {
        key
        value
      }
      secondaryID
      secondaryIDMapping
      serialNumber
      siteId
      siteName
      siteTimezone
      softwareVersion
      sop {
        key
        value
      }
      status
      systemOwner
      systemStatus
      variant
      createdBy
      updatedBy
      editReason
      editComment
      configurationDetails
    }
  }
`;

export const DELETE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  mutation deleteDigitalLabInstrumentRepositoryEntry($inventoryId: ID!) {
    deleteDigitalLabInstrumentRepositoryEntry(input: { inventoryId: $inventoryId }) {
      inventoryId
      linkedInstance
      materialNumber
      secondaryID
      secondaryIDMapping
      serialNumber
      siteName
      status
    }
  }
`;

export const CREATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE = gql`
  mutation createDigitalLabInstrumentRepositoryUserProfile(
    $email: String!
    $lastFilter: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    createDigitalLabInstrumentRepositoryUserProfile(
      input: {
        email: $email
        lastFilter: $lastFilter
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      lastFilter
      status
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const UPDATE_DIGITAL_LAB_INSTRUMENT_USER_PROFILE_PERSONAL_FIELDS = gql`
  mutation updateDigitalLabInstrumentRepositoryUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String
    $lastDisplayColumns: String
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    $lastPageSize: String
    $searchPreference: String
  ) {
    updateDigitalLabInstrumentRepositoryUserProfile(
      input: {
        id: $id
        email: $email
        lastFilter: $lastFilter
        lastDisplayColumns: $lastDisplayColumns
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
        lastPageSize: $lastPageSize
        searchPreference: $searchPreference
      }
    ) {
      id
      email
      lastFilter
      lastDisplayColumns
      status
      site
      familyName
      givenName
      name
      user
      phone
      lastPageSize
      searchPreference
    }
  }
`;

export const CREATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER = gql`
  mutation createUserFilter($userID: ID!, $filterName: String!, $filterDefinition: String, $filterModel: String!) {
    createUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
        filterModel: $filterModel
      }
    ) {
      filterName
      id
      filterDefinition
      userID
      filterModel
    }
  }
`;

export const UPDATE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER = gql`
  mutation updateUserFilter($userID: ID!, $filterName: String!, $filterDefinition: String, $filterModel: String!) {
    updateUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
        filterModel: $filterModel
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const DELETE_DIGITAL_LAB_INSTRUMENT_PAGE_USER_FILTER = gql`
  mutation deleteUserFilter($userID: ID!, $filterName: String!, $filterModel: String!) {
    deleteUserFilter(input: { userID: $userID, filterName: $filterName, filterModel: $filterModel }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const CREATE_IR_GROUP_ADMIN_MAPPING = gql`
  mutation createIRGroupAdminMapping(
    $reviewers: [CreateDigitalLabInstrumentRepositoryUserInput]
    $userIds: [CreateDigitalLabInstrumentRepositoryUserInput]!
    $groupName: String!
  ) {
    createIRGroupAdminMapping(
      input: { userIds: $userIds, reviewers: $reviewers, groupName: $groupName, modelName: "IRGroupAdminMapping" }
    ) {
      userIds {
        email
        familyName
        givenName
        name
        userId
      }
      reviewers {
        email
        familyName
        givenName
        name
        userId
      }
      modelName
      groupName
    }
  }
`;

export const UPDATE_IR_GROUP_ADMIN_MAPPING = gql`
  mutation updateIRGroupAdminMapping(
    $reviewers: [CreateDigitalLabInstrumentRepositoryUserInput]
    $userIds: [CreateDigitalLabInstrumentRepositoryUserInput]!
    $groupName: String!
  ) {
    updateIRGroupAdminMapping(
      input: { userIds: $userIds, reviewers: $reviewers, groupName: $groupName, modelName: "IRGroupAdminMapping" }
    ) {
      groupName
      modelName
      userIds {
        email
        familyName
        givenName
        name
        userId
      }
      reviewers {
        email
        familyName
        givenName
        name
        userId
      }
    }
  }
`;

export const DELETE_IR_GROUP_ADMIN_MAPPING = gql`
  mutation deleteIRGroupAdminMapping($groupName: String!) {
    deleteIRGroupAdminMapping(input: { modelName: "IRGroupAdminMapping", groupName: $groupName }) {
      groupName
      modelName
      userIds
    }
  }
`;

export const CREATE_IR_REASON_MASTER = gql`
  mutation createReasonMaster($configText: String!) {
    createIRConfigurableMaster(input: { configText: $configText }) {
      id
      configText
    }
  }
`;

export const UPDATE_IR_REASON_MASTER = gql`
  mutation updateIRConfigurableMaster($configText: String!, $id: ID!) {
    updateIRConfigurableMaster(input: { configText: $configText, id: $id }) {
      id
      configText
    }
  }
`;

export const DELETE_IR_REASON_MASTER = gql`
  mutation deleteIRConfigurableMaster($id: ID!) {
    deleteIRConfigurableMaster(input: { id: $id, modelName: "IREDITREASON" }) {
      id
      configText
    }
  }
`;

export const CREATE_IR_UPLOAD_STATUS = gql`
  mutation createIRUploadStatus(
    $email: String!
    $fileName: String
    $modelName: String!
    $s3VersionId: String!
    $status: UploadStatusEnum
    $targetModule: String
    $uploadDate: String!
  ) {
    createIRUploadStatus(
      input: {
        email: $email
        fileName: $fileName
        modelName: $modelName
        s3VersionId: $s3VersionId
        status: $status
        targetModule: $targetModule
        uploadDate: $uploadDate
      }
    ) {
      email
      userName {
        items {
          userId
          givenName
          familyName
          email
        }
      }
      uploadDate
      targetModule
      status
      s3VersionId
      modelName
      fileName
      errorFilePath
      errorFileName
    }
  }
`;

export const CREATE_IR_DISPLAY_COLUMN = gql`
  mutation createUserDisplay($userID: ID!, $displayName: String!, $displayDefinition: String, $displayModel: String!) {
    createUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
        displayModel: $displayModel
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const UPDATE_IR_DISPLAY_COLUMN = gql`
  mutation updateUserDisplay($userID: ID!, $displayName: String!, $displayDefinition: String, $displayModel: String!) {
    updateUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
        displayModel: $displayModel
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const DELETE_IR_DISPLAY_COLUMN = gql`
  mutation deleteUserDisplay($userID: ID!, $displayName: String!, $displayModel: String!) {
    deleteUserDisplay(input: { userID: $userID, displayName: $displayName, displayModel: $displayModel }) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const UPDATE_IR_INBOX = gql`
  mutation updateIRInbox($status: InboxStatusEnum, $id: ID!) {
    updateIRInbox(input: { status: $status, id: $id }) {
      id
      emailId
      status
    }
  }
`;

export const DEACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  mutation deactivateDigitalLabInstrumentRepositoryEntry($id: ID!, $editReason: String!, $editComment: String!) {
    deactivateDigitalLabInstrumentRepositoryEntry(
      input: { id: $id, editReason: $editReason, editComment: $editComment }
    ) {
      id
      status
    }
  }
`;

export const ACTIVATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  mutation activateDigitalLabInstrumentRepositoryEntry($id: ID!, $editReason: String!, $editComment: String!) {
    activateDigitalLabInstrumentRepositoryEntry(
      input: { id: $id, editReason: $editReason, editComment: $editComment }
    ) {
      id
      status
    }
  }
`;
export const DECOMMISSION_CLUSTER = gql`
  mutation decommissionCluster($clusterId: String!, $editReason: String!, $editComment: String!) {
    decommissionCluster(clusterId: $clusterId, editReason: $editReason, editComment: $editComment) {
      clusterId
      isDecommissioned
    }
  }
`;

export const MANAGE_CLUSTER = gql`
  mutation manageCluster($cluster: CreateClusterDigitalLabInstrumentRepositoryEntryInput!) {
    manageCluster(input: $cluster) {
      id
    }
  }
`;

export const CREATE_BULK_LINKED_INSTANCE = gql`
  mutation createIRBulkLinkedInstanceChange(
    $editReason: String!
    $emailId: String!
    $inventoryIds: [String]!
    $targetLinkedInstance: String!
    $editComment: String
    $createdById: ID
    $status: InstanceChangeStatusEnum
  ) {
    createIRBulkLinkedInstanceChange(
      input: {
        emailId: $emailId
        inventoryIds: $inventoryIds
        editReason: $editReason
        editComment: $editComment
        createdById: $createdById
        status: $status
        targetLinkedInstance: $targetLinkedInstance
      }
    ) {
      status
      targetLinkedInstance
      inventoryIds
      emailId
      editReason
      editComment
    }
  }
`;

export const CREATE_BULK_EDIT_EQUIPMENT_LIST = gql`
  mutation createIRBulkEdit(
    $editReason: String!
    $emailId: String!
    $filter: String!
    $change: IRBulkEditChangeInput
    $editComment: String
    $createdById: ID
    $status: InstanceChangeStatusEnum
    $reviewRequiredInventoryIds: [String]
    $reviewers: [String]
  ) {
    createIRBulkEdit(
      input: {
        emailId: $emailId
        filter: $filter
        editReason: $editReason
        editComment: $editComment
        createdById: $createdById
        status: $status
        change: $change
        reviewRequiredInventoryIds: $reviewRequiredInventoryIds
        reviewers: $reviewers
      }
    ) {
      status
      change {
        dateOfLastMaintanance
        dateOfNextMaintanance
        documentedMaintenanceDate
      }
      emailId
      editReason
      editComment
    }
  }
`;

export const CREATE_IR_CATEGORY_MAPPING = gql`
  mutation createIRCategoryMapping(
    $equipmentCategory: String!
    $manufacturer: String!
    $equipmentModel: String!
    $variant: String!
    $displayImage: String
    $weightInkg: Float
    $heightInmm: Int
    $lengthInmm: Int
    $depthInmm: Int
    $clearanceLeftInmm: Int
    $clearanceRightInmm: Int
    $clearanceHeightInmm: Int
    $clearanceToFrontInmm: Int
    $clearanceBackwardsInmm: Int
    $isActive: Boolean
    $modelName: String!
    $createdById: ID
    $heatOutputInW: Int
    $pressureEquipment: String
    $noiseIndBA: Int
    $noiseSource: String
    $noiseSourceDetails: String
    $positioningNote: String
    $positioning: String
    $vibrationSensitive: String
  ) {
    createIRCategoryMapping(
      input: {
        equipmentCategory: $equipmentCategory
        manufacturer: $manufacturer
        equipmentModel: $equipmentModel
        variant: $variant
        displayImage: $displayImage
        weightInkg: $weightInkg
        heightInmm: $heightInmm
        lengthInmm: $lengthInmm
        depthInmm: $depthInmm
        clearanceLeftInmm: $clearanceLeftInmm
        clearanceRightInmm: $clearanceRightInmm
        clearanceHeightInmm: $clearanceHeightInmm
        clearanceToFrontInmm: $clearanceToFrontInmm
        clearanceBackwardsInmm: $clearanceBackwardsInmm
        isActive: $isActive
        createdById: $createdById
        modelName: $modelName
        heatOutputInW: $heatOutputInW
        pressureEquipment: $pressureEquipment
        noiseIndBA: $noiseIndBA
        noiseSource: $noiseSource
        noiseSourceDetails: $noiseSourceDetails
        positioningNote: $positioningNote
        positioning: $positioning
        vibrationSensitive: $vibrationSensitive
      }
    ) {
      equipmentCategory
      equipmentModel
      modelName
      variant
      manufacturer
    }
  }
`;

export const UPDATE_IR_CATEGORY_MAPPING = gql`
  mutation updateIRCategoryMapping($input: UpdateIRCategoryMappingInput!) {
    updateIRCategoryMapping(input: $input) {
      StatusCode
      data {
        proposedChanges
        entryTable
      }
      error
    }
  }
`;

export const EQUIPMENT_APPROVE_REVIEW = gql`
  mutation approveChange($reviewIds: [String], $editReason: String, $editComment: String) {
    approveChange(input: { reviewIds: $reviewIds, editReason: $editReason, editComment: $editComment }) {
      status
    }
  }
`;

export const EQUIPMENT_REJECT_REVIEW = gql`
  mutation rejectChange($editComment: String, $editReason: String, $reviewIds: [String]) {
    rejectChange(
      input: { editComment: $editComment, editReason: $editReason, reviewIds: $reviewIds, action: "REJECT" }
    ) {
      status
    }
  }
`;
export const EQUIPMENT_REVERT_REVIEW = gql`
  mutation revertChange($editComment: String, $editReason: String, $reviewIds: [String]) {
    revertChange(
      input: { editComment: $editComment, editReason: $editReason, reviewIds: $reviewIds, action: "REVERT" }
    ) {
      status
    }
  }
`;

export const UPDATE_EQUIPMENT_WITH_REVIEW = gql`
  mutation updateEquipmentWithReview(
    $inventoryId: String!
    $reviewers: [String]!
    $newData: UpdateDigitalLabInstrumentRepositoryEntryInput!
  ) {
    updateEquipmentWithReview(input: { inventoryId: $inventoryId, reviewers: $reviewers, newData: $newData }) {
      inventoryId
      status
    }
  }
`;
export const CREATE_REVIEW_REQUIRED_TRACKING = gql`
  mutation createReviewRequiredTracking($id: ID!, $query: String!, $updateRecord: String!, $createdById: ID) {
    createReviewRequiredTracking(
      input: { id: $id, query: $query, updateRecord: $updateRecord, createdById: $createdById }
    ) {
      id
    }
  }
`;
export const CREATE_INSTRUMENT_REPOSITORY_AUDIT = gql`
  mutation createInstrumentRepositoryAudit(
    $action: String!
    $inventoryId: String!
    $secondaryId: String!
    $secondaryIdMapping: String!
    $modifiedDate: String!
    $linkedInstanceRefId: String!
    $newData: String!
    $oldData: String!
    $modifiedByUserId: String!
    $modifiedByEmail: String!
    $changes: String!
  ) {
    createInstrumentRepositoryAudit(
      input: {
        action: $action
        inventoryId: $inventoryId
        secondaryId: $secondaryId
        secondaryIdMapping: $secondaryIdMapping
        modifiedDate: $modifiedDate
        linkedInstanceRefId: $linkedInstanceRefId
        newData: $newData
        oldData: $oldData
        modifiedByUserId: $modifiedByUserId
        modifiedByEmail: $modifiedByEmail
        changes: $changes
      }
    ) {
      modifiedByUserId
    }
  }
`;

export const CREATE_CHECKLIST = gql`
  mutation createChecklist(
    $description: String!
    $url: String!
    $models: [String!]!
    $sites: [String!]!
    $groups: [String!]!
  ) {
    createChecklist(input: { description: $description, url: $url, models: $models, sites: $sites, groups: $groups }) {
      description
      url
      models
      sites
      groups
      createdAt
      status
    }
  }
`;

export const DELETE_CHECKLIST = gql`
  mutation deleteChecklist($createdAt: AWSTimestamp!) {
    deleteChecklist(input: { createdAt: $createdAt }) {
      success
    }
  }
`;
